<template>
	<div class="wrapper mypage">
		
		<div class="mypage-header">
			<div class="header-left-area">
				<div class="page-back-btn-area" @click="ClickBack">
					<a class="back-btn"></a>
				</div>
				<div class="page-logo">
					<NuxtLink class="site-title" to="/">
						<NuxtImg src="/img/Shakeme_logo_neon.svg" title="shakeme" />
					</NuxtLink>
				</div>
			</div>
			<div class="header-right-area">
				<a v-if="login === false" href="/login" class="login-btn">Login</a>
				<a v-else-if="login === true" href="/member" class="user-btn" title="マイページへ">
					<NuxtImg src="/img/icon-user.svg" />
				</a>
			</div>
		</div>

		<slot></slot>
		

		<div class="loading-page">
			<p>ローディング</p>
		</div>

		<div class="mypage-footer">
			<div class="footer-menu">
				<div class="left">
					<div class="name">
						<NuxtImg src="/img/Shakeme_logo_neon.svg" />
					</div>
					<div class="text">
						<p>
							<span class="sub">運営会社</span>
							<span>株式会社PATURN</span>
						</p>
						<p>
							<span>〒162-0843</span>
							<span>東京都新宿区市谷田町3-8市ヶ谷科学技術イノベーションセンタービル2F</span>
						</p>
						<p>
							<span class="sub">お問い合わせ先</span>
							<a href="mailto:contact@shakeme.jp">contact@shakeme.jp</a>
						</p>
						<p>
							<small>※ご返信は通常3営業日以内にさせていただきます。</small>
						</p>
					</div>
				</div>
				<div class="right">
					<ul>
						<li><a href="https://paturn.co.jp/privacypolicy" target="_blank" rel="noopener">プライバシーポリシー</a></li>
						<li><a href="/document/scta" target="_blank" rel="noopener">特定商取引法に基づく表記</a></li>
						<li><a href="/document/termsofuse" target="_blank" rel="noopener">利用規約</a></li>
						<li><a href="/event/hayashi/c75d808f-b09e-4638-83aa-a9ae88173f61/#faq" target="_blank" rel="noopener">よくある質問</a></li>
						<li><a href="/document/howtouse" target="_blank" rel="noopener">使い方</a></li>
					</ul>
				</div>
			</div>
			<div class="copyright">copyright &copy; 2024 PATURN Ltd. All Rights Reserved.</div>
		</div>
	</div>
</template>

<script setup>
	const login = useState('login');

	useHead({
		bodyAttrs: {
			class: 'mypage'
		}
	})

	const ClickBack = () => {
		// // 履歴があるかどうかをフラグで確認
		// let historyExists = false;
		
		// // タイムアウトを設定
		// const timeout = setTimeout(() => {
		// 	if (!historyExists) {
		// 		// 履歴がない場合にリダイレクト
		// 		window.location.href = "/event/hayashi/c75d808f-b09e-4638-83aa-a9ae88173f61";
		// 	}
		// }, 100); // 100ミリ秒後に実行
		
		// // 履歴があればタイムアウトをクリアしてbackを実行
		// window.onpopstate = () => {
		// 	historyExists = true;
		// 	clearTimeout(timeout);
		// };
		
		// backを実行
		history.back();
	}

</script>